<template>
  <div class=" mb-3 mt-5 pt-3">
    <RegisterConfirmationPhoneNumber
      @setOtp="setOtp"
      @setErrorMessage="setErrorMessage"
      :timestamp="timestamp"
      :isStartCountdown="startCountdown"
      :otp="otp"
      ref="otpRegister"
      :errorMessage="errorMessage"
      :phoneNumber="phoneNumber"
      @validatePhoneNumber="validatePhoneNumber"
      @resendOtp="resendOtp"
    />
  </div>
</template>

<script>
import RegisterConfirmationPhoneNumber from '@/components/cleanui/system/Auth/RegConfPhoneNumber'
import Cookies from 'js-cookie'

export default {
  components: {
    RegisterConfirmationPhoneNumber,
  },
  computed: {
    phoneNumber () {
      return this.$store.state.user.phone
    },
  },
  data: function () {
    return {
      timestamp: 0,
      otp: '',
      startCountdown: null,
      errorMessage: '',
    }
  },
  created () {
    window.addEventListener('beforeunload', this.handleUserReloadPage)
    this.startCountdown = true
  },
  methods: {
    setOtp (value) {
      this.otp = value
    },
    setErrorMessage (value) {
      this.errorMessage = value
    },
    validatePhoneNumber () {
      this.$store.dispatch('user/VALIDATEPHONENUMBER', {
        user_uuid: this.$store.state.user.uuid,
        otp_code: this.otp,
        login: true,
      })
        .then(() => {
          this.$router.push({ path: '/auth/register/success' })
        })
        .catch((err) => {
          this.errorMessage = err.response.message || 'Internal Server Error'
        })
    },
    handleUserReloadPage () {
      if (this.$refs.otpRegister.otpExpired) {
        Cookies.set('otpResendDelay', this.$refs.otpRegister.otpExpired)
      } else {
        Cookies.remove('otpResendDelay')
      }
    },
    resendOtp () {
      this.startCountdown = null
      this.$store.dispatch('user/RESENDOTP', {
        user_uuid: this.$store.state.user.uuid,
      })
        .then(() => {
          this.startCountdown = true
        })
        .catch((err) => {
          this.errorMessage = err.response.message || 'Internal Server Error'
        })
    },
  },
}
</script>
