var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{class:{
      [_vm.$style.card__register]: true,
    }},[_c('div',{class:{
        [_vm.$style.card__register__image]: true,
      }},[_c('img',{attrs:{"src":"https://ik.imagekit.io/powerbiz/img/admin-saas/Logo.png?updatedAt=1631759657482","width":"131","height":"63"}})]),_c('div',{staticClass:"pbz-font subtitle-md-medium mt-5 mb-3 d-flex justify-content-center align-items-center",staticStyle:{"color":"#1A1A1A"}},[_vm._v(" "+_vm._s(_vm.$t('auth.verifyAccount'))+" ")]),_c('div',{staticClass:"pbz-font body-md-regular my-3 d-flex justify-content-center align-items-center",staticStyle:{"color":"#999999"}},[_vm._v(" "+_vm._s(_vm.$t('auth.sendOtpTo'))+" ")]),_c('div',{staticClass:"pbz-font body-md-medium mt-3 d-flex justify-content-center align-items-center",staticStyle:{"color":"#1A1A1A"}},[_vm._v(" "+_vm._s(_vm.phoneNumber)+" ")]),_c('div',{staticClass:"my-3 d-flex justify-content-center align-items-center"},[_c('OTP',{attrs:{"is-submited":_vm.isSubmited},on:{"on-change":_vm.handleOnChangeOtp,"on-complete":_vm.handleOtpCompleted}})],1),(_vm.errorMessage.length)?_c('div',{staticClass:"d-flex justify-content-center align-items-center my-3"},[_c('div',{staticClass:"pbz-font body-md-medium",staticStyle:{"color":"#EE4426"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])]):_vm._e(),_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"pbz-font body-md-medium",staticStyle:{"color":"#1A1A1A !important"}},[_vm._v(" Kirim ulang kode dalam "),_c('span',{staticStyle:{"color":"#0559CB !important"}},[_vm._v(_vm._s(_vm.minutes)+" : "+_vm._s(_vm.seconds)+" ")])])]),_c('div',{staticClass:"my-3 d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"pbz-font body-sm-bold",style:({
          color: !_vm.minutes && !_vm.seconds ? '#0559CB' : '#999999',
          cursor: !_vm.minutes && !_vm.seconds && 'pointer',
        }),on:{"click":function($event){$event.preventDefault();return (() => _vm.$emit('resendOtp')).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('auth.resendOtp'))+" ")])]),_c('div',{staticClass:"mt-5 mb-2 d-flex justify-content-center align-items-center",class:{
        [_vm.$style.anotherRegisterCard]: true,
      },style:({
        backgroundColor: _vm.otp.length === 6 && ((_vm.minutes && _vm.seconds) || (!_vm.minutes && _vm.seconds)) ? '#0559CB !important' : '#999999 !important' ,
        border: _vm.otp.length === 6 && ((_vm.minutes && _vm.seconds) || (!_vm.minutes && _vm.seconds)) ? 'ipx solid #0559CB !important' : '1px solid #999999 !important' ,
        cursor: _vm.otp.length === 6 && ((_vm.minutes && _vm.seconds) || (!_vm.minutes && _vm.seconds)) && 'pointer'
      }),on:{"click":function($event){$event.preventDefault();return (() => _vm.otp.length === 6 && ((_vm.minutes && _vm.seconds) || (!_vm.minutes && _vm.seconds)) && _vm.$emit('validatePhoneNumber')).apply(null, arguments)}}},[(!_vm.loading)?_c('div',{staticClass:"pbz-font button-md-medium",staticStyle:{"color":"#FFFFFF !important"}},[_vm._v(" Validasi ")]):_vm._e(),(_vm.loading)?_c('div',[_c('a-icon',{attrs:{"type":"loading"}}),_vm._v(" Loading ")],1):_vm._e()]),_c('div',{staticClass:"my-2",class:{
        [_vm.$style.changePhoneNumber]: true,
      },on:{"click":function($event){$event.preventDefault();return (() => _vm.$router.push({ path: '/auth/register' })).apply(null, arguments)}}},[_c('div',{staticClass:"pbz-font button-md-medium",staticStyle:{"color":"#0559CB !important"}},[_vm._v(" Ubah Nomer Ponsel ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }