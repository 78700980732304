<template>
  <div class="row">
    <div
      :class="{
        [$style.card__register]: true,
      }"
    >
      <div
        :class="{
          [$style.card__register__image]: true,
        }"
      >
        <img
          src="https://ik.imagekit.io/powerbiz/img/admin-saas/Logo.png?updatedAt=1631759657482"
          width="131"
          height="63"
        />
      </div>

      <div
        class="pbz-font subtitle-md-medium mt-5 mb-3 d-flex justify-content-center align-items-center"
        style="color: #1A1A1A"
      >
        {{ $t('auth.verifyAccount') }}
      </div>

      <div
        class="pbz-font body-md-regular my-3 d-flex justify-content-center align-items-center"
        style="color: #999999"
      >
        {{ $t('auth.sendOtpTo') }}
      </div>

      <div
        class="pbz-font body-md-medium mt-3 d-flex justify-content-center align-items-center"
        style="color: #1A1A1A"
      >
        {{ phoneNumber }}
      </div>

      <div class="my-3 d-flex justify-content-center align-items-center">
        <OTP :is-submited="isSubmited" @on-change="handleOnChangeOtp" @on-complete="handleOtpCompleted" />
      </div>

      <div v-if="errorMessage.length" class="d-flex justify-content-center align-items-center my-3">
        <div class="pbz-font body-md-medium" style="color: #EE4426">
          {{ errorMessage }}
        </div>
      </div>

      <div class="d-flex justify-content-center align-items-center">
        <div style="color: #1A1A1A !important" class="pbz-font body-md-medium">
          Kirim ulang kode dalam <span style="color: #0559CB !important;">{{ minutes }} : {{ seconds }} </span>
        </div>
      </div>

      <div class="my-3 d-flex justify-content-center align-items-center">
        <div
          class="pbz-font body-sm-bold"
          :style="{
            color: !minutes && !seconds ? '#0559CB' : '#999999',
            cursor: !minutes && !seconds && 'pointer',
          }"
          @click.prevent="() => $emit('resendOtp')"
        >
          {{ $t('auth.resendOtp') }}
        </div>
      </div>

      <div
        :class="{
          [$style.anotherRegisterCard]: true,
        }"
        class="mt-5 mb-2 d-flex justify-content-center align-items-center"
        :style="{
          backgroundColor: otp.length === 6 && ((minutes && seconds) || (!minutes && seconds)) ? '#0559CB !important' : '#999999 !important' ,
          border: otp.length === 6 && ((minutes && seconds) || (!minutes && seconds)) ? 'ipx solid #0559CB !important' : '1px solid #999999 !important' ,
          cursor: otp.length === 6 && ((minutes && seconds) || (!minutes && seconds)) && 'pointer'
        }"
        @click.prevent="() => otp.length === 6 && ((minutes && seconds) || (!minutes && seconds)) && $emit('validatePhoneNumber')"
      >
        <div v-if="!loading" class="pbz-font button-md-medium" style="color: #FFFFFF !important">
          Validasi
        </div>
        <div v-if="loading">
          <a-icon type="loading" /> Loading
        </div>
      </div>
      <div
        :class="{
          [$style.changePhoneNumber]: true,
        }"
        class="my-2"
        @click.prevent="() => $router.push({ path: '/auth/register' })"
      >
        <div class="pbz-font button-md-medium" style="color: #0559CB !important">
          Ubah Nomer Ponsel
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OTP from '@/components/OTP/index'

export default {
  components: {
    OTP,
  },
  props: {
    // timestamp: {
    //   type: String,
    // },
    isStartCountdown: {
      type: Boolean,
    },
    otp: {
      type: String,
    },
    modalState: {
      type: Boolean,
    },
    phoneNumber: {
      type: String,
    },
    errorMessage: {
      type: String,
    },
  },
  data: function () {
    return {
      actualTime: this.$moment().format('X'),
      minutes: 0,
      seconds: 0,
      isSubmited: false,
      otpExpired: this.$store.state.user.otpResendDelay,
    }
  },
  computed: {
    loading () {
      return this.$store.state.user.loading
    },
  },
  watch: {
    actualTime () {
      if (!this.seconds && !this.minutes) {
        return null
      } else {
        this.compute()
      }
    },
    isStartCountdown (newValue) {
      if (newValue) {
        this.otpExpired = this.$store.state.user.otpResendDelay
        this.startCountdown()
      }
    },
  },
  created: function () {
    if (this.isStartCountdown) {
      this.startCountdown()
    }
  },
  methods: {
    addOneSecondToActualTime () {
      this.actualTime = this.$moment().format('X')
      setTimeout(() => {
        if (!this.minutes && !this.seconds) {
          clearInterval()
        } else {
          this.otpExpired = this.otpExpired - 1
          this.addOneSecondToActualTime()
        }
      }, 1000)
    },
    // getDiffInSeconds () {
    //   return 180
    // },
    compute () {
      const duration = this.$moment.duration(this.otpExpired, 'seconds')
      this.minutes = duration.minutes() > 0 ? duration.minutes() : 0
      this.seconds = duration.seconds() > 0 ? duration.seconds() : 0
    },
    startCountdown () {
      this.compute()
      this.addOneSecondToActualTime()
    },
    handleOtpCompleted (otp) {
      this.$emit('setOtp', otp)
    },
    handleOnChangeOtp (otp) {
      this.$emit('setOtp', otp)
    },
    submit () {
      this.isSubmited = true
    },
  },
}
</script>

<style lang="scss" module>
@import "@/components/cleanui/system/Auth/style.module.scss";
</style>
